import { ClassNames, CheckboxInput } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import { AppContext } from '../context/appProvider';
import {
  TechForGoodCategories,
  SupportCategories,
  getFriendlyCategoryName,
  Category,
  CategoryWithDescription,
} from '../typings/data';

import chevronUp from '../assets/chevron-up.svg';
import chevronDown from '../assets/chevron-down.svg';

import './filterList.scss';

interface IProps {
  expandable?: boolean;
}

const FilterList: React.FC<IProps> = ({ expandable }) => {
  const { filterMode, filterCategories, setFilterCategories, resetFilterCategories } = React.useContext(AppContext);
  const [expanded, setExpanded] = React.useState(!expandable);

  React.useEffect(() => {
    if (expandable) {
      const removeFocus = () => setExpanded(false);
      window.addEventListener('click', removeFocus);

      return () => {
        window.removeEventListener('click', removeFocus);
      };
    }
  }, [expanded]);

  return (
    <div className="filters-wrapper" data-expandable={expandable} onClick={(e) => e.stopPropagation()}>
      {expandable && (
        <button onClick={() => setExpanded(!expanded)}>
          Filter companies
          <img alt="Chevron" src={expanded ? chevronUp : chevronDown} />
        </button>
      )}
      {expanded && (
        <div className="filters-list">
          {(filterMode === 'tech-for-good' ? SupportCategories : TechForGoodCategories)
            .slice()
            .sort()
            .map((c: CategoryWithDescription) => (
              <label key={c.name}>
                <div className={ClassNames.concat('icon-wrapper', c.name)}>
                  <div className="icon-inner">
                    <img alt={c.name} src={`/icons/${c.name}.svg`} />
                  </div>
                </div>
                <div className="filter-label">{getFriendlyCategoryName(c.name)}</div>
                <input
                  checked={filterCategories.indexOf(c.name) !== -1}
                  onChange={() => setFilterCategories(c.name)}
                  type="checkbox"
                />
              </label>
            ))}
          <button onClick={resetFilterCategories}>Clear filters</button>
        </div>
      )}
    </div>
  );
};

export default FilterList;
