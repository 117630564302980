import { ClassNames, SwitchInput, useDialog } from '@rocketmakers/armstrong-edge';
import * as React from 'react';
import CompanyPanel from '../components/companyPanel';
import FilterList from '../components/filterList';
import Footer from '../components/footer';
import { AppContext } from '../context/appProvider';
import { exportToCsv } from '../helpers/csvHelpers';
import Shell from '../layout/shell';
import { getFriendlyCategoryName, ILocation } from '../typings/data';

import './data.scss';

interface Props {
  heading: string;
}

const Data: React.FC<Props> = (props) => {
  const { allLocations, filteredLocations, filterMode, setFilterMode, filterCategories, setFilterCategories } =
    React.useContext(AppContext);

  const [selectedLocation, setSelectedLocation] = React.useState<ILocation>();

  const [showLocationDialog, { isOpen }] = useDialog<boolean>(
    // the contents of the modal
    ({ resolve }) => <CompanyPanel isCardsPage={true} location={selectedLocation!} />
  );

  React.useEffect(() => {
    if (selectedLocation) {
      showLocationDialog();
    }
  }, [selectedLocation]);

  React.useEffect(() => {
    if (selectedLocation) {
      setSelectedLocation(undefined);
    }
  }, [isOpen]);

  const exportCsv = React.useCallback(() => {
    const headers = ['Name', 'Description', 'Category', 'City', 'Postcode', 'URL'];
    const data = allLocations.map((l) => [l.name, l.description, l.category, l.city, l.postcode, l.webUrl]);
    data.unshift(headers);
    exportToCsv('tech-for-good.csv', data!);
  }, [allLocations]);

  return (
    <Shell>
      <div className="fixed-width">
        <div className="card-filters">
          <div className="actions fixed-width">
            <div className="data-download">
              <button onClick={exportCsv}>Download List</button>
              <a href="/about#data" className="copyright">
                Attribution-ShareAlike 4.0 International (CC BY-SA 4.0)
                <div>
                  <img className="copyright-img" src={`./icons/copyright.svg`} />
                </div>
              </a>
            </div>
            <FilterList expandable />
          </div>
          <div className={ClassNames.concat('card-filter-toggle', filterMode)}>
            <label>Tech for Good Companies</label>
            <SwitchInput
              checked={filterMode === 'support'}
              onChange={(e) => setFilterMode(filterMode === 'tech-for-good' ? 'support' : 'tech-for-good')}
            />
            <label>The Support Community</label>
          </div>
        </div>

        <div className="cards">
          {filteredLocations.map((l, i) => (
            <div key={l.name} className={ClassNames.concat('card', l.category)} onClick={() => setSelectedLocation(l)}>
              <div className={`card-header bg-${l.category}`}>
                <div className="icon-wrapper">
                  <div className="icon-inner">
                    <img alt={l.category} src={`/icons/${l.category}.svg`} />
                  </div>
                </div>
                {getFriendlyCategoryName(l.category)}
              </div>
              <div className="card-content">
                <strong>{l.name}</strong>
                <div className="card-footer">
                  {l.postcode}
                  <div>{l.city}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </Shell>
  );
};

export default Data;
