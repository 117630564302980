import * as React from 'react';
import { ClassNames } from '@rocketmakers/armstrong-edge';
import cross from '../assets/icons/cross.svg';
import './companyPanel.scss';
import { getFriendlyCategoryName, ILocation } from '../typings/data';

interface IProps {
  location: ILocation;
  isCardsPage?: boolean;
}

const CompanyPanel: React.FC<IProps> = ({ location, isCardsPage }) => {
  const [panelOpen, setPanelOpen] = React.useState(true);
  return (
    <div
      className={
        !panelOpen
          ? ClassNames.concat('panel company-content', isCardsPage && 'isCardsPage', location.category, 'closed')
          : ClassNames.concat('panel company-content', isCardsPage && 'isCardsPage', location.category, 'open')
      }
    >
      {!isCardsPage && (
        <div className="panel-tab-container" onClick={() => setPanelOpen(!panelOpen)}>
          <div className="panel-tab">
            {panelOpen ? <img src={`/icons/left.svg`} /> : <img src={`/icons/right.svg`} />}
            <p>Company Profile</p>
          </div>
        </div>
      )}
      {panelOpen && (
        <>
          <div className="panel-header">
            <div className="icon-wrapper">
              <img src={`/icons/${location.category}.svg`} />
            </div>
            {!isCardsPage && (
              <div className="cross-mark" onClick={() => setPanelOpen(false)}>
                <img src={cross} />
              </div>
            )}
          </div>
          <div className="panel-content">
            <h2>{location.name}</h2>
            <div className="location">{`${location.city}, ${location.postcode}`}</div>
            <div className="category-wrapper">
              <div className={ClassNames.concat('icon-wrapper', location.category)}>
                <div className="icon-inner">
                  <img alt={location.category} src={`/icons/${location.category}.svg`} />
                </div>
              </div>
              <div className="inner">
                <h4>{getFriendlyCategoryName(location.category)}</h4>
              </div>
            </div>

            {location.description && <div className="description">{location.description}</div>}
            <div className="btn-container">
              {location.webUrl && (
                <a className="btn" target="_blank" href={location.webUrl}>
                  <img src={`/icons/cursor.svg`} /> {`Visit ${location.name}'s website`}
                </a>
              )}
              {location.twitterUrl && (
                <a className="btn" target="_blank" href={location.twitterUrl}>
                  <img src={`/icons/twitter.svg`} /> {`Follow on Twitter`}
                </a>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CompanyPanel;
